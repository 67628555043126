<template>
  <div>
    <h1 class="text-primary-1 fw-bold fs-1 mb-4">Payment info</h1>
    <div
      class="
        bg-primary-5
        border-r-10
        p-4
        d-flex
        flex-column
        justify-content-center
      "
    >
      <h2 class="fs-3 text-primary-0 fw-bold m-0 mb-4">Billing history</h2>
      <data-table
        :value="billing_history"
        :rowHover="billing_history.length > 0"
        size="normal"
        :loading="loading"
        responsiveLayout="scroll"
        selectionMode="single"
      >
        <template #empty> No billing history found. </template>
        <column
          v-for="(column, index) in studentBilling.table.header"
          :field="column.field"
          :header="column.name"
          style="min-width: 14rem"
          :key="index"
        >
          <template #body="{ data }">
            <div
              class="
                bg-white
                py-1
                px-3
                bl-shadow
                d-flex
                gap-2
                rounded-pill
                w-content
                align-items-center
              "
              v-if="!['date', 'amount'].includes(column.field)"
            >
              <div class="status" :class="bgStatus(data[column.field])"></div>
              <span class="text-neutro-1 first-letter-uppercase">
                {{ data[column.field] }}
              </span>
            </div>
            <p v-if="column.field !== 'payment_status'">
              {{
                column.field === "date"
                  ? moment(data[column.field]).format("MM/DD/yyyy")
                  : "$" + data[column.field]
              }}
            </p>
          </template>
        </column>
      </data-table>
    </div>
  </div>
</template>

<script>
import useStudents from "@/modules/admin/composables/Members/Students/useStudents";
import formatDate from "@/shared/utils/dates/formatDate";
import bgStatus from "@/shared/utils/style/bgStatus";
import moment from "moment";
export default {
  props: {
    billing_history: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    const { studentBilling } = useStudents();

    return {
      studentBilling,
      formatDate,
      bgStatus,
      moment
    };
  },
};
</script>