import { ref, computed, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";
import { ref as refDb, set, get, child, onValue } from "firebase/database";
import database from "@/firebase/config";
import { FilterMatchMode } from "primevue/api";
import { useRouter } from "vue-router";
import filterDateRanges from "@/modules/admin/helpers/filterDateRanges";
import moment from "moment";

const useStudent = () => {
  const store = useStore();
  const router = useRouter();

  const page = ref(1);
  const limit = ref(20);
  const gender = ref();
  const age = ref();
  const level = ref();
  const canceledVal = ref();
  const codeVal = ref();
  const videoVal = ref();
  const find = ref();
  const startDate = ref();
  const endDate = ref();
  const orderBy = ref("");
  const dateRange = ref();
  const statusVal = ref();

  const setInfoStatusStudents = () => {
    let { tables } = store.getters["admins/getMemberStudents"];
    tables.basicInfo.data.forEach((student) => {
      const dbRef = refDb(
        database,
        "Spanish/" + student.token_firebase + "/status"
      );
      onValue(dbRef, async (snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val() === "offline") {
            student.status = "offline";
          } else {
            get(
              child(dbRef, "Spanish/" + student.token_firebase + "/last_status")
            )
              .then((snapshot2) => {
                if (
                  snapshot2.val() !== "online" &&
                  snapshot2.val() !== "offline"
                ) {
                  student.status = snapshot.val();
                } else student.status = "online";
              })
              .catch((error) => {
                console.error(error);
              });
          }
        } else {
          student.status = "offline";
        }
      });
    });
    store.commit("admins/setStudentsBasicInfo", {
      data: tables.basicInfo.data,
    });
  };

  const defaultHeader = [
    { field: "status", name: "Status", sortable: true, filter: true },
    { field: "id", name: "Student ID", sortable: true, filter: false },
    { field: "name", name: "Name(s)", sortable: false },
    { field: "gender", name: "Gender", sortable: true, filter: true },
    { field: "phone", name: "Phone", sortable: false },
    {
      field: "timezone",
      name: "Time zone",
      sortable: true,
      filter: false,
    },
    { field: "paymentMethod", name: "Payment method", sortable: true },
    { field: "enrollment", name: "Enrollment", sortable: true },

    { field: "email", name: "Email", sortable: false },
    { field: "birth", name: "Birthday", sortable: false },
    { field: "age", name: "Age", sortable: true, filter: true },
    { field: "level", name: "Level", sortable: true, filter: true },
    {
      field: "enrollmentDate",
      name: "Enrollment date",
      sortable: true,
    },
    {
      field: "firstPaymentDate",
      name: "First payment date",
      sortable: true,
    },

    {
      field: "effective",
      name: "Effective number of classes held",
      sortable: true,
    },
    {
      field: "canceled",
      name: "Number of canceled classes ",
      sortable: true,
      filter: true,
    },
    {
      field: "code",
      name: "Applied discount code",
      sortable: true,
      filter: true,
    },
    {
      field: "video",
      name: "Rewards video",
      sortable: true,
      filter: true,
    },
  ];

  // onBeforeMount(async () => {
  //   let { currentOptionReport, header } =
  //     store.getters["admins/getMemberStudents"].tables.basicInfo;
  //   if (!currentOptionReport || currentOptionReport === "default") {
  //     store.commit("admins/setStudentsBasicInfo", { header: defaultHeader });
  //     await store.dispatch("admins/getStudents");
  //     setInfoStatusStudents();
  //   } else {
  //     const currentOption = optionsReports.find(
  //       (report) => report.id === currentOptionReport
  //     );
  //     if (currentOption) {
  //       store.dispatch("admins/getStudentsByReport", {
  //         option: currentOptionReport,
  //       });
  //       header = header.filter((header) =>
  //         currentOption.fields.includes(header.field)
  //       );
  //       store.commit("admins/setStudentsBasicInfo", { header });
  //       setInfoStatusStudents();
  //     }
  //   }
  // });

  const toggleLeadModal = () => {
    store.commit("admins/memberStudentsLeadModal", { isOpen: true });
  };

  const tableOptions = ref([{ label: "Basic info", value: "basic-info" }]);

  const currentTable = ref("basic-info");
  const changeTable = (option) => {
    currentTable.value = option;
  };

  const basicInfoTable = computed(() => {
    const students = store.getters["admins/getMemberStudents"].tables.basicInfo;

    const datesRanges_ = students.dateRanges ?? [];
    const hasDateRanges =
      students.dateRanges.filter((date) => date).length === 2;
    if (hasDateRanges) {
      students.data = filterDateRanges({
        datesRanges: datesRanges_,
        data: students.data,
        fieldDate: "enrollment_date",
      });
    }

    return students;
  });

  const clearDateRanges = async () => {
    page.value = 1;
    store.commit("admins/setStudentsBasicInfo", { dateRanges: [] });
    const { currentOptionReport, header } =
      store.getters["admins/getMemberStudents"].tables.basicInfo;
    if (currentOptionReport === "default" || !currentOptionReport) {
      await store.dispatch("admins/getStudents", generateQuery.value);
      store.commit("admins/setStudentsBasicInfo", { header: defaultHeader });
      setInfoStatusStudents();
    } else {
      const currentOption = optionsReports.find(
        (report) => report.id === currentOptionReport
      );
      if (currentOption) {
        await store.dispatch("admins/getStudentsByReport", [
          {
            option: currentOptionReport,
          },
          generateQuery.value,
        ]);
        store.commit("admins/setStudentsBasicInfo", { header });
        setInfoStatusStudents();
      }
    }
  };

  const basicInfoFilters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    cellphone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    age: { value: "", matchMode: FilterMatchMode.IN },
    gender: { value: [], matchMode: FilterMatchMode.IN },
    enrollment: { value: [], matchMode: FilterMatchMode.IN },
    birth: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phone_code_id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    level: { value: [], matchMode: FilterMatchMode.IN },
    time_zone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: [], matchMode: FilterMatchMode.IN },
    count: { value: "", matchMode: FilterMatchMode.IN },
    apply_discount_code: { value: "", matchMode: FilterMatchMode.IN },
    rewards_video: { value: "", matchMode: FilterMatchMode.IN },
  });

  const isBasicInfoSearch = ref(false);
  const toggleBasicInfoSearch = () => {
    isBasicInfoSearch.value = !isBasicInfoSearch.value;
  };

  const columns = ref([
    { field: "status", header: "Status", sortable: true },
    { field: "id", header: "Student ID", sortable: false },
    { field: "name", header: "Name", sortable: false },
    { field: "gender", header: "Gender", sortable: true },
    { field: "email", header: "Email", sortable: false },
    { field: "phone", header: "Phone", sortable: false },
    { field: "paymentMethod", header: "Payment method", sortable: true },
    { field: "enrollment", header: "Enrollmnent", sortable: true },
    { field: "birth", header: "Birthday", sortable: false },
    { field: "age", header: "Age", sortable: true },
    { field: "level", header: "Level", sortable: true },
    { field: "enrollmentDate", header: "Enrollment date", sortable: true },
    {
      field: "firstPaymentDate",
      header: "First payment date",
      sortable: true,
    },
    {
      field: "effective",
      header: "Effective number of classes held",
      sortable: true,
    },
    {
      field: "canceled",
      header: "Number of classes canceled",
      sortable: true,
      filter: true,
    },
    {
      field: "code",
      header: "Applied discount code",
      sortable: true,
      filter: true,
    },
    { field: "video", header: "Rewards video", sortable: true },
  ]);

  const goToPage = ({ id, name }) => {
    const route = { name };
    if (id) route.params = { id };
    router.push(route);
  };

  const studentBilling = computed(
    () => store.getters["admins/getMemberStudents"].billing
  );

  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    lesson_start: { value: "", matchMode: FilterMatchMode.CONTAINS },
    teacher: { value: "", matchMode: FilterMatchMode.CONTAINS },
  });

  const fields = ref(["lesson_start", "teacher", "feedback"]);

  const optionsReports = [
    {
      text: "Default",
      id: "default",
      fields: [],
    },
    {
      text: "Drops",
      id: "drops",
      fields: [
        "id",
        "name",
        "email",
        "phone",
        "enrollment_date",
        "city",
        "level",
        "gender",
        "payment-method",
        "age",
      ],
    },
    {
      text: "Leads",
      id: "leads",
      fields: ["name", "email", "phone"],
    },
    {
      text: "Active students",
      id: "active-students",
      fields: [
        "id",
        "name",
        "email",
        "phone",
        "enrollment_date",
        "city",
        "payment-method",
        "age",
        "level",
        "gender",
      ],
    },
    {
      text: "Trial students",
      id: "trial",
      fields: [
        "id",
        "name",
        "email",
        "phone",
        "enrollment_date",
        "city",
        "payment-method",
        "age",
        "level",
        "gender",
      ],
    },
    {
      text: "Canceled classes",
      id: "canceled-classes",
      fields: ["id", "name", "email", "phone", "enrollment_date", "count"],
    },
    {
      text: "Absent",
      id: "absent",
      fields: [
        "id",
        "name",
        "email",
        "phone",
        "enrollment_date",
        "city",
        "level",
      ],
    },
  ];

  const onChangeOptionReport = async (option) => {
    page.value = 1;
    let { header } = store.getters["admins/getMemberStudents"].tables.basicInfo;
    if (option.value === "default" || !option.value) {
      await store.dispatch("admins/getStudents", generateQuery.value);
      store.commit("admins/setStudentsBasicInfo", { header: defaultHeader });
      setInfoStatusStudents();
      return;
    }
    const currentOption = optionsReports.find(
      (report) => report.id === option.value
    );

    if (currentOption) {
      await store.dispatch("admins/getStudentsByReport", [
        {
          option: option.value,
        },
        generateQuery.value,
      ]);
      header = defaultHeader.filter((header) =>
        currentOption.fields.includes(header.field)
      );

      header = header.filter((header) =>
        currentOption.fields.includes(header.field)
      );
      store.commit("admins/setStudentsBasicInfo", { header });
      setInfoStatusStudents();
    }
  };

  const onChangeColumns = (value) => {
    store.commit("admins/setStudentsBasicInfo", { selectedColumns: value });
  };

  const feedbackStudentOrTeacher = computed(() => {
    return store.getters["admins/feedbackStudentOrTeacher"];
  });

  const toggleFeedbackStudentOrTeacher = (data) => {
    const { isOpen } = store.getters["admins/feedbackStudentOrTeacher"];
    const isStudent = data.hasOwnProperty("teacher");
    store.commit("admins/feedbackStudentOrTeacher", {
      isOpen: !isOpen,
      data,
      isStudent,
    });
  };

  const getInfoFeedback = () => {
    const feedbacks =
      store.getters["admins/feedbackStudentOrTeacher"].data.feedback;
    return _.groupBy(feedbacks, (feedback) => feedback.title);
  };

  const getListings = computed(() => {
    return store.getters["shared/getListings"];
  });

  const onPage = async (e) => {
    page.value = e.page + 1;
    let { currentOptionReport, header } =
      store.getters["admins/getMemberStudents"].tables.basicInfo;
    if (!currentOptionReport || currentOptionReport === "default") {
      store.commit("admins/setStudentsBasicInfo", { header: defaultHeader });
      await store.dispatch("admins/getStudents", generateQuery.value);
      setInfoStatusStudents();
    } else {
      const currentOption = optionsReports.find(
        (report) => report.id === currentOptionReport
      );
      if (currentOption) {
        store.dispatch("admins/getStudentsByReport", [
          {
            option: currentOptionReport,
          },
          generateQuery.value,
        ]);
        header = header.filter((header) =>
          currentOption.fields.includes(header.field)
        );
        store.commit("admins/setStudentsBasicInfo", { header });
        setInfoStatusStudents();
      }
    }
  };

  const onSort = (e) => {
    const direction = e.sortOrder == 1 ? "_asc" : "_desc";
    const oderBy = e.sortField + direction;
    orderBy.value = oderBy;
    onPage({ page: 0 });
  };
  const search = () => {
    onPage({ page: 0 });
  };

  const generateQuery = computed(() => {
    return (
      (page.value ? `page=${page.value}` : "") +
      (limit.value ? `&limit=${limit.value}` : "") +
      (gender.value ? `&gender=${gender.value}` : "") +
      (age.value ? `&age=${age.value}` : "") +
      (level.value ? `&level=${level.value}` : "") +
      (canceledVal.value ? `&canceled=${canceledVal.value}` : "") +
      (codeVal.value ? `&code=${codeVal.value}` : "") +
      (videoVal.value ? `&video=${videoVal.value}` : "") +
      (find.value ? `&find=${find.value}` : "") +
      (startDate.value ? `&startDate=${startDate.value}` : "") +
      (endDate.value ? `&endDate=${endDate.value}` : "") +
      (orderBy.value ? `&orderBy=${orderBy.value}` : "") +
      (statusVal.value ? `&status=${statusVal.value}` : "")
    );
  });

  const filterApply = (e) => {
    onPage({ page: 0 });
  };
  const filterClear = (e) => {
    gender.value = null;
    age.value = null;
    level.value = null;
    canceled.value = null;
    code.value = null;
    video.value = null;
    onPage({ page: 0 });
  };

  watch(dateRange, (newValue) => {
    if (newValue[1]) {
      startDate.value = moment(newValue[0]).format("YYYY-MM-DD");
      endDate.value = moment(newValue[1]).format("YYYY-MM-DD");
      onPage({ page: 0 });
    }
  });

  const changeLimit = (e) => {
    limit.value = e;
  };

  return {
    getListings,
    changeLimit,
    toggleFeedbackStudentOrTeacher,
    getInfoFeedback,
    filters,
    fields,
    onChangeColumns,
    feedbackStudentOrTeacher,
    toggleLeadModal,
    currentTable,
    changeTable,
    tableOptions,
    columns,
    clearDateRanges,
    basicInfoTable,
    basicInfoFilters,
    goToPage,
    studentBilling,
    isBasicInfoSearch,
    toggleBasicInfoSearch,
    optionsReports,
    onChangeOptionReport,
    setInfoStatusStudents,
    defaultHeader,
    onPage,
    page,
    onSort,
    generateQuery,
    find,
    search,
    gender,
    age,
    level,
    canceledVal,
    codeVal,
    videoVal,
    filterApply,
    filterClear,
    dateRange,
    statusVal,
  };
};

export default useStudent;
