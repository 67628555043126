import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref as refDb, set, onValue, get, child } from "firebase/database";
import database from "@/firebase/config";

const useStudentPayment = () => {
  const route = useRoute();
  const { id } = route.params;
  const store = useStore();
  const isLoading = ref(true);
  const student = ref({
    name: "",
    last_name: "",
    age: "",
    gender: "",
    timezone: "",
    photo: "",
    menber_since: "",
    status: "",
    payment_date: "",
    billing_history: [],
    status_firebase: "",
  });

  const getStatusFirebase = (token) => {
    const dbRef = refDb(
      database,
      "Spanish/" + token + "/status"
    );
    // console.log(token)
    onValue(dbRef, async (snapshot) => {
      let status = snapshot.val();
      // console.log(snapshot.val())
      if (snapshot.val() === "offline") {
        status = "offline";
        student.value.status_firebase = status;
      } else {
        get(child(dbRef, "Spanish/" + token + "/last_status"))
          .then((snapshot2) => {
            if (
              snapshot2.val() !== "online" &&
              snapshot2.val() !== "offline"
            ) {
              status = snapshot.val();
            } else status = "online";
            student.value.status_firebase = status;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  onBeforeMount(async () => {
    const { success, json } = await store.dispatch(
      "admins/getStudentPaymentById",
      { id }
    );

    isLoading.value = false;
    if (success) {
      student.value = json;
      getStatusFirebase(student.value.token_firebase);
    }
  });

  return { isLoading, student };
};

export default useStudentPayment;
