<template>
  <skeleton
    v-if="isLoading"
    borderRadius="10px"
    height="12rem"
    width="100%"
    class="mb-3 skeleton-dark"
  />
  <div
    v-else
    class="
      student-information
      p-4
      pe-5
      bg-primary-5
      border-r-10
      d-flex
      justify-content-between
      gap-4
      mb-3
    "
  >
    <i
      class="pi pi-arrow-left text-primary-1 fs-4 fw-bold pointer"
      @click="goToPage({ name: 'admin-members-students' })"
    ></i>
    <div class="header-user- header-user-big w-100 text-center">
      <div class="position-relative text-center user-picture">
        <avatar
          :image="photo ?? '/assets/images/avatar.png'"
          shape="circle"
        />
        <div
          class="
            user-status
            w-auto
            bg-white
            py-1
            px-2
            shadow-sm
            d-flex
            gap-2
            align-items-center
          "
        >
          <div class="status" :class="true ? (statusFirebase !== undefined ? bgStatus(statusFirebase) : 'loading...' ) : ''"></div>
          <span class="fs-6">{{statusFirebase !== "" && statusFirebase !== undefined ? statusFirebase?.slice(0,1).toUpperCase() + statusFirebase?.slice(1) : "loading..."}}</span>
        </div>
      </div>
    </div>
    <div class="w-100">
      <h1 class="fs-3 fw-bold">
        {{ name ?? "-------" }}
        <span v-if="lastname"> {{ lastname }}</span>
      </h1>
      <div class="d-flex gap-3">
        <span class="fs-6">{{ age ?? "--" }} years</span>
        <span class="fs-6">|</span>
        <span class="fs-6">{{ gender ?? "----" }}</span>
      </div>
      <p class="fs-6 my-1">
        {{ timezone }}
      </p>
      <h2 class="fs-6 my-1">Member since: {{ moment(menber_since).format('MMMM DD, YYYY') }}</h2>
    </div>
    <div class="border-primary-1 border-r-10 w-100 p-3 h-content">
      <div class="d-flex gap-3 align-items-center mb-2" v-if="status">
        <p class="fs-6 fw-bold">Status:</p>
        <div
          class="
            w-auto
            py-1
            px-2
            bl-shadow
            rounded-pill
            d-flex
            gap-2
            align-items-center
            bg-secondary-1
            text-white
            px-3
            py-2
          "
        >
          <span class="fs-6">
            {{ status }}
          </span>
        </div>
      </div>
      <p class="fs-6">
        <span class="fw-bold">Payment date:</span> {{ formatDate(payment_date,'american') ?? "--" }}
      </p>
    </div>
  </div>
</template>

<script>
import useStudents from "@/modules/admin/composables/Members/Students/useStudents";
import formatDate from '@/shared/utils/changeTexts/formatDate'
import bgStatus from "@/shared/utils/style/bgStatus";
import moment from "moment";
export default {
  props: {
    name: {
      type: String,
    },
    age: {
      type: String,
    },
    gender: {
      type: String,
    },
    timezone: {
      type: String,
    },
    status: {
      type: String,
    },
    payment_date: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
    statusFirebase: {
      type: String,
    },
    menber_since: {
      type: String,
    },
    lastname: {
      type: String,
    },
    /* ,
    currentTable: {
      type: String,
      required: true,
    },
    changeTable: {
      type: Function,
    }, */
  },
  setup() {
    const { goToPage } = useStudents();
    return {
      bgStatus,
      goToPage,
      formatDate,
      moment
    };
  },
};
</script>