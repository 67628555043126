<template>
  <banner-component
    :name="student.name"
    :lastname="student.last_name"
    :age="student.age"
    :timezone="student.timezone_change"
    :photo="student.photo"
    :menber_since="student.menber_since"
    :status="student.status"
    :payment_date="student.payment_date"
    :isLoading="isLoading"
    :statusFirebase="student?.status_firebase"
    :gender="student.gender"
  />
  <table-billing-component
    :billing_history="student.billing_history"
    :loading="isLoading"
  />
</template>

<script>
import BannerComponent from "@/modules/admin/components/Members/Students/Billing/BannerComponent";
import TableBillingComponent from "@/modules/admin/components/Members/Students/Billing/TableBillingComponent";
import useStudentPayment from "@/modules/admin/composables/Members/Students/useStudentPayment";

export default {
  components: {
    BannerComponent,
    TableBillingComponent,
  },
  setup() {
    const { student, isLoading } = useStudentPayment();
    return { student, isLoading };
  },
};
</script>